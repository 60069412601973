import React from 'react'

const Playables = () => {
  return (
      <>
        <h2>Playables</h2>
        <p>Suff I like to play.</p>
      </>
  )
}

export default Playables