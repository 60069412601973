import React from 'react'

const Readables = () => {
  return (
    <>
        <h2>Readables</h2>
        <p>A list of articles I found interesting or helpful.</p>
    </>
  )
}

export default Readables