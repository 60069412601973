let socials = [
    {
      name: 'LinkedIn',
      address: 'https://www.linkedin.com/in/alaaddinh/',
    },
    {
      name: 'Github',
      address: 'https://www.github.com/alokbya',
    },
    {
      name: 'Twitter',
      address: 'https://twitter.com/AlaaHiefield'
    }
  ];
  
  export function getSocials() {
    return socials;
  }