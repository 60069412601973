import React from 'react'

const Distractions = () => {
  return (
      <>
        <h2>Distractions</h2>
        <p>I plan to present my own writing here, but I've got nothing to show just yet.</p>
    </>
  )
}

export default Distractions